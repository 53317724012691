//================================================================
//  Component: Browse
//================================================================

//  Purpose: Table that contains the users datasets


//  Properties:
//    - filesFiltered = {useState, holds the dataset documents}
//    - dataSetsFiltered = {useState, holds the filtered dataset documents}
//    - setFilesFiltered = {useState, holds the filtered dataset documents}


//  Example:
//      <Browse
//          filesFiltered={filesFiltered}
//          dataSetsFiltered={dataSetsFiltered}
//          setFilesFiltered={setFilesFiltered}
//      ></Browse>

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

//Contexts
import { SetToast } from '../../Library/GlobalContexts';

//Functions
import QueryDocument from '../../Library/QueryDocument';
import ConvertDate from '../../Library/ConvertDate';
import DownloadBlob from '../../Library/DownloadBlob';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import BreadCrumb from './BreadCrumb/BreadCrumb';
import DownloadInput from './DownloadInput/DownloadInput';

//Images
import TableSorting from '../../Components/TableFiltersSorting/TableSorting';
import IconSearch from '../../Components/Images/Icon_Search_Grey.svg';


export default function Browse() {

    //------------------------------------------------------
    //  React Router & Contexts
    //------------------------------------------------------

    const params = useParams();
    const [searchParams] = useSearchParams();
    
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to save the page status > 'pending', 'onload', 'success', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Holds the current files & filtered files
    const [files, setFiles] = useState([]);
    const [filesFiltered, setFilesFiltered] = useState([]);

    // Column sorting states
    const [searchInput, setSearchInput] = useState('');

    // Column sorting states
    const [currentSort, setCurrentSort] = useState({
        'column': 'Name',
        'sort': 'descending',
    });

    // Files the user wants to download
    const [filesToDownload, setFilesToDownload] = useState([]);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Search for a filename
    function handleSearch(value) {

        setSearchInput(value);

        if (value.length === 0) {

            return setFilesFiltered([...files]);

        }

        const results = filesFiltered.filter((object) =>
            object?.filename?.toLowerCase().includes(value.toLowerCase())
        );

        const options = [];
        results.forEach((object) => {

            options.push(object.application);

        });

        setFilesFiltered(results);

    }

    // Requests the signed URL from Firebase GCS
    function handleDownload() {

        if (filesToDownload.length === 0) return;

        // Open the toast
        setToast({
            'type': 'pending', // info, pending, success, warning, error
            'message': 'Preparing for download',
        });

        const downloadPromises = [];

        // Loop through each file and create a downloadable link
        filesToDownload.forEach((row) => {

            downloadPromises.push(
                DownloadBlob(`${row.folderpath}${row.filename}`, row.datasetid),
            );

        });

        // Open each link in a new tab
        Promise.all(downloadPromises).then((urls) => {

            urls.forEach((blob, index) => {

                // Create URL from blob
                const url = window.URL.createObjectURL(blob);

                // Create new Element -> Add the file name & URl
                const a = document.createElement('a');
                a.download = filesToDownload[index].filename;
                a.href = url;

                // Trigger download & Cleanup
                a.click();

            });

            setFilesToDownload([]);

            // Close toast
            setToast({
                'type': '',
                'message': '',
            });

        }).catch((error) => {
    
            console.log(error);

            // Set error on toast
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to prepare link(s)',
            });

        });

    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onLoad
    //  - Datset is set once, so we need the list of 'application' list
    useEffect(() => {

        const dataSetId = params.id;

        if (dataSetId === '') return;
        if (dataSetId === null) return;

        QueryDocument('files', [
            ['datasetid', '==', dataSetId],
            ['folderpath', '==', searchParams.get('path')],
        ], ['filename', 'desc']).then((documents) => {

            setFiles(documents);
            setFilesFiltered(documents);
            setPageStatus('onload');

        }).catch((error) => {

            console.log(error);
            setPageStatus('error-fatal');

        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <PageComponent
            status={pageStatus}
            body={

                <div className='Table-Container'>

                    <BreadCrumb></BreadCrumb>

                    {/* ======== Sorting and Filters ========== */}
                    <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                        <label className='font-semibold self-center'>
                            Total: {filesFiltered.length} of {files.length}
                        </label>

                        <div className='flex flex-row gap-2'>

                            <button className='Primary-Button' style={{margin: '0px'}} disabled={filesToDownload.length === 0 ? true : false} onClick={() => handleDownload()}>
                                Prepare Download
                            </button>

                            <div className='flex flex-row gap-2'>

                                {/* ======== Search Bar ======== */}
                                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                    <label htmlFor='searchInput'>
                                        <input
                                            id='searchInput'
                                            className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                            type='text'
                                            placeholder='Search'
                                            onChange={(e) => handleSearch(e.target.value)}
                                            value={searchInput}
                                        ></input>
                                    </label>
                                </div>

                            </div>

                        </div>


                    </div>

                    <table className='w-full max-h-96 overflow-y-scroll'>
                        <thead>
                            <tr className='border-b-[#D8D8D8] border-solid border-b-2'>

                                <TableSorting
                                    column='Name'
                                    currentSort={currentSort}
                                    setCurrentSort={setCurrentSort}
                                    filteredTableArray={filesFiltered}
                                    setFilteredTableArray={setFilesFiltered}
                                    objectKey='filename'
                                ></TableSorting>
                                <TableSorting
                                    column='Last Updated'
                                    currentSort={currentSort}
                                    setCurrentSort={setCurrentSort}
                                    filteredTableArray={filesFiltered}
                                    setFilteredTableArray={setFilesFiltered}
                                    objectKey='lastupdated'
                                ></TableSorting>
                                <TableSorting
                                    column='Created'
                                    currentSort={currentSort}
                                    setCurrentSort={setCurrentSort}
                                    filteredTableArray={filesFiltered}
                                    setFilteredTableArray={setFilesFiltered}
                                    objectKey='created'
                                ></TableSorting>
                                <TableSorting
                                    column='Type'
                                    currentSort={currentSort}
                                    setCurrentSort={setCurrentSort}
                                    filteredTableArray={filesFiltered}
                                    setFilteredTableArray={setFilesFiltered}
                                    objectKey='contenttype'
                                ></TableSorting>
                                <TableSorting
                                    column='Size (KB)'
                                    currentSort={currentSort}
                                    setCurrentSort={setCurrentSort}
                                    filteredTableArray={filesFiltered}
                                    setFilteredTableArray={setFilesFiltered}
                                    objectKey='filesize'
                                ></TableSorting>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                // No files found
                                filesFiltered.length === 0 ?
                                    (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className='text-center p-[2%]'>
                                                    No files found.
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    (
                                        // Map in each file/folder
                                        filesFiltered.map((row, index) => (
                                            <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>
                                                <td className='py-[15px] px-[20px] font-medium'>
                                                    <DownloadInput
                                                        row={row}
                                                        filesToDownload={filesToDownload}
                                                        setFilesToDownload={setFilesToDownload}
                                                    ></DownloadInput>
                                                </td>
                                                <td className='py-[15px] px-[20px]'>{ConvertDate(row?.lastupdated)}</td>
                                                <td className='py-[15px] px-[20px]'>{ConvertDate(row?.created)}</td>
                                                <td className='py-[15px] px-[20px]'>
                                                    {
                                                        // show file type or folder
                                                        row?.contenttype === 'folder' ?
                                                            'File folder'
                                                            :
                                                            row?.contenttype
                                                    }
                                                </td>
                                                <td className='py-[15px] px-[20px]'>
                                                    {
                                                        // show file size for files or blank for folder
                                                        row?.contenttype === 'folder' ?
                                                            ''
                                                            :
                                                            row?.filesize !== 0 ? (Math.ceil(row?.filesize / 1024 * 100) / 100): 0
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    )
                            }
                        </tbody>
                    </table>

                </div>

            }
        ></PageComponent>

    )

}
